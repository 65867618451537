// Here you can add other styles
.main .container-fluid {
  padding: 0 15px;
}
.displayTableList .card-header {
  display: none;
}
.displayTableList .card-body {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

.add-button-div {
  width: 100%;
  margin: 10px auto;
}

// .displayTableList .card-body a {
//     width: 50%;
// }
// .displayTableList .card-body a button, .displayTableList .card-body a + label {
//     margin-bottom: 16px;
// }
.addNewElementClass,
.displayTableList .card-body a + label {
  margin-bottom: 16px;
}
.displayTableList .react-bootstrap-table-pagination,
.displayTableList a.page-link {
  width: 100%;
  align-items: center;
}
.displayTableList ul.pagination {
  margin: 0;
}
.displayTableList span.react-bootstrap-table-pagination-total {
  padding: 16px;
}
.displayTableList button {
  margin: 0 5px;
}

.updateStatus {
  cursor: pointer;
}

.invalid-feedback-text {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545 !important;
}
.preview-img {
  height: 100px !important;
}

li.dropdown-item {
  cursor: pointer;
}

button.sizePerPageaTag.btn.btn-secondary {
  background-color: inherit;
  border: none;
}

.dropdown-btn-col-w {
  background-color: #ffffff;
}

.dropdown-p-28 {
  padding: 2px 8px;
}

.pac-container {
  z-index: 9999 !important;
}

ul.city_list {
  padding: 2px;
  z-index: 99;
}

ul.city_list > li {
  padding: 5px;
  list-style: none;
  font-size: 12px;
  border-top: 1px solid #dadada;
  border-left: 1px solid #dadada;
  border-right: 1px solid #dadada;
}

ul.city_list > li:last-child {
  border-bottom: 1px solid #dadada;
}
ul.city_list > li > a,
ul.city_list > li > a:hover {
  color: #262727;
  text-decoration: none;
}
.w-150 {
  width: 150px;
}

.filters-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.border-custom {
  border: 1px solid #e4e7ea !important;
}

.input-group-text-custom {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #5c6873;
  text-align: center;
  white-space: nowrap;
  background-color: #f0f3f5;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0px none transparent;
}
// Css for time picker
.timepicker-inline {
  display: inline-block;
}

.btn-info,
.btn-info:hover {
  color: #ffffff !important ;
}

.form-check-inline .form-check-input,
input[type="radio"],
input[type="checkbox"] {
  // margin-top: -1px;
  vertical-align: middle;
}

.form-check-input.test-price-checkbox {
  margin-top: -10px;
}

.preview_container {
  left: 45%;
}

.form-group.from_commission .form-check {
  padding: 0;
}

.form-group.from_commission .form-check .commission_label {
  width: 110px;
}

.form-group.from_commission
  .form-check
  .form-check.form-check-inline
  label.form-check-label {
  width: 50px;
}

.form-group.from_commission .form-check .form-group {
  width: 100%;
  margin: 8px 0;
}
.col-rating {
  color: #ffca00;
}
//Css for duty hours
.time_picker_container .react_times_button.time_picker_preview {
  width: 74px;
  font-size: 12px;
  margin-top: -10px;
}
.designshiv .react_times_button.time_picker_preview .preview_container {
  position: relative;
  left: 40px;
}
.designshiv .time_picker_container {
  float: left;
}
.designshiv .col-md-6.timepicker-inline label {
  float: left;
  width: auto !important;
}
.designshiv .react_times_button.time_picker_preview {
  height: 40px;
}
.row.designshiv {
  width: 290px;
  float: left;
  position: absolute;
  right: 0;
  top: 0px;
}
.dutyHourContainer.card-body .form-group {
  position: relative;
  margin-bottom: 25px;
}
.form-group.paymentpage label {
  width: 100%;
  float: left;
}
.form-group.paymentpage .form-check.form-check-inline {
  width: 47%;
  margin: 0 0 10px;
}
.form-group.paymentpage
  .form-check.form-check-inline
  label.ml-3.mr-3.form-check-label {
  margin: 0px !important;
  padding: 0;
}
.preview_container svg {
  width: 25px;
  height: 22px;
  top: 5px !important;
}
.designshiv .react_times_button.time_picker_preview {
  height: 31px;
  margin-top: -5px;
}

.card .card-body.dutyHourContainer .form-group label {
  width: 75px;
  font-size: 12px;
  margin: 2px 2px 0 !important;
}
.designshiv .preview_container {
  position: absolute;
  left: 50%;
  height: 30px;
  line-height: 30px;
  padding-left: 30px;
}
.displayInlineBlock {
  display: inline-block;
}

// css for bubble notification count
.bubbleCount {
  position: absolute;
  border-radius: 100%;
  background-color: #f30301;
  color: #fff;
}

/* Css for certificate status */
.br-lf-success {
  border-left: 6px solid #4dbd74;
}
.br-lf-danger {
  border-left: 6px solid #f63c3a;
}
.br-lf-warning {
  border-left: 6px solid #ffc107;
}

.form-horizontal .preview-img {
  margin: 10px;
}

/* Css for EmployerHeaderProfilePic */
:root {
  --avatar-size: 2.8rem;
  /* change this value anything, e.g., 100px, 10rem, etc. */
}

.circle {
  background-color: #10a5f5;
  border-radius: 50%;
  height: var(--avatar-size);
  //text-align: center;
  width: var(--avatar-size);
  //align-items:center;
}

.baseInitials {
  color: #fff;
  align-items: center;
  font-family: "Courier New", monospace;
  font-size: calc(var(--avatar-size) / 3); /* 50% of parent */
  font-weight: bold;
  line-height: 1;
  position: relative;
  top: calc(var(--avatar-size) / 4); /* 25% of parent */
}

.initials1 {
  left: 1px;
  margin-top: -2px;
  font-size: calc(var(--avatar-size) / 1.8);
}

.initials2 {
  right: 5px;
  margin-top: -1.5px;
  font-size: calc(var(--avatar-size) / 2);
}

.initials3 {
  right: 7px;
  margin-top: 2px;
  font-size: calc(var(--avatar-size) / 2.5);
}

.initials4 {
  right: 9px;
  margin-top: 3px;
  font-size: calc(var(--avatar-size) / 3);
}

.initials5 {
  right: 10px;
  margin-top: 4px;
  font-size: calc(var(--avatar-size) / 3.5);
}

.center-text-notFound {
  padding-top: 10%;
  text-align: center;
  justify-content: "center";
}

.password-box {
  position: relative;
}

.password-box .eye-login {
  top: 32px !important;
  right: 18px !important;
}

.cap {
  text-transform: capitalize;
}

.image-preview {
  padding: 10px;
  width: 200px;
  cursor: pointer;
  border: 1px solid #000;
  margin-top: 10px;
}

.or-css {
  margin-left: 30%;
  margin-top: 7%;
  color: lightslategrey;
}

// insurance card preview css
.document-preview {
  width: 200px;
  position: relative;
  padding: 10px;
  text-align: center;
  display: grid;
  cursor: pointer;
}

.document-preview .fa {
  font-size: 50px;
}

.document-preview .preview-filename {
  font-weight: 500;
}

// Insurance label CSS
.in-sub-heading {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e4e6f1;
}

.in-row {
  display: grid;
  margin-bottom: 10px;
}

.in-label {
  font-weight: 500;
}

.inline-input {
  display: inline !important;
  width: 200px !important;
}

.text-strong {
  font-weight: 500;
}

.mr-15 {
  margin-right: 15px;
}

.consent-button-section,
.signed-consent-btn-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-header .close {
  padding: 0;
  margin: 0;
  font-size: 2.5rem;
  position: absolute;
  right: 10px;
  top: -5px;
}

.signature-pad-wrapper-style {
  display: flex;
  justify-content: center;
  align-items: center;
}

.signature-canvas {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 20px 0 30px;
}

.consent-form-content {
  border: 1px solid #000;
}

.consent-form-content h4 {
  background-color: #2f5496;
  margin-top: 0;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  font-size: 15px;
  font-weight: bold;
}

.consent-form-content h5,
.consent-form-content p,
.consent-form-content li {
  padding: 0 10px;
}

.consent-form-content h5 {
  font-weight: bold;
  font-size: 14px;
  margin: 10px 0;
}

.consent-form-content p,
.consent-form-content li {
  font-size: 14px;
  margin: 10px 0 10px !important;
}

.page-header .breadcrumb-item a {
  color: #0581ea !important;
}

.page-header .breadcrumb-item.active {
  color: #051441 !important;
}

.round-button-position {
  display: flex;
  align-items: center;
}

.round-button-position i {
  margin-left: 8px;
  font-style: normal !important;

  &:hover {
    cursor: pointer;
  }
}

.app-content .page-header .page-title {
  line-height: 1 !important;
}

.h3-red {
  color: red;
}
.test-env {
  margin: 0;
  padding: 0;
  position: relative;
  left: calc(50% - 196px);
  display: flex;
  align-items: center;
}

.signed-consent-btn-section,
.refill-consent-btn-section {
  margin-bottom: 20px;
}
.signed-consent-btn-section .btn,
.refill-consent-btn-section .btn {
  white-space: normal;
}

@media (max-width: 991.98px) {
  .test-env {
    font-size: 12px;
    left: 5px;
  }
}

.immgration-form-header {
  background-color: #e0e0e0;
  border: 1px solid rgb(56, 56, 56);
  border-radius: 5px;
}

.immgration-form-sub-header {
  background-color: #e0e0e0;
  font-style: italic;
  padding: 5px;
  width: 100% !important;
  margin-bottom: 10px;
}

.text-large {
  font-size: 18px;
}

.pl-25 {
  padding-left: 25px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

.pl-75 {
  padding-left: 75px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pl-110 {
  padding-left: 110px !important;
}

.link {
  color: blue;
}

.table-input {
  max-width: 130px !important;
}

.immigration-accordian.accordion .accordion-header {
  border: none;
  padding: unset;
}

.immigration-accordian.accordion .accordion-body {
  padding: 15px 25px 50px;
}
